export const mapValueToEnumKey = <T extends Record<keyof T, string>>(
  enumType: T,
  value: string | number,
  defaultValue?: T[keyof T],
): T[keyof T] => {
  const keys = Object.keys(enumType) as (keyof T)[];
  const foundKey = keys.find((key: keyof T) => enumType[key] === value);

  if (!foundKey) {
    if (defaultValue) {
      return defaultValue;
    }
    throw new Error(`Value '${value}' not found in enum`);
  }

  return enumType[foundKey];
};
